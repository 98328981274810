import React, { useContext, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { routes } from "routes";

import { useAppDispatch, useAppSelector } from "store";
import { fetchSummaryOverview } from "store/summary/thunks";
import { clearState, setIsLoadingAnimationActive } from "store/summary/summarySlice";
import { useQueryParams } from "hooks/useQueryParams";

import styled from "styled-components";
import nlpLoading from "assets/animations/nlp-loading.webm";
import nlpLoadingMac from "assets/animations/nlp-loading-mac.mov";

import { MostCriticalInsights } from "./MostCriticalInsights";
import { Text } from "components/Text";
import { TextTruncator } from "components/TextTruncator";
import { AnalysisContext } from "context/AnalysisContext";
import { FRAME_TRANSITION } from "components/_screens/WelcomeScreen/constants";
import { AnimatedText } from "components/AnimatedText";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { SharingSummaryAccess, WidgetType } from "@explorance/mly-types";

import { SystemOS, getSystemOs } from "utils/getSystemOS";
import { ErrorScreen } from "components/ErrorScreen";
import { PageErrorType } from "ts/enums/pageErrorType";

export const SummaryPage = ({ printMode = false }) => {
  const state = useAppSelector((state) => state.summary);
  const { features } = useAppSelector((state) => state.settings);
  const { currentUser } = useAppSelector((state) => state.auth);
  const [analysisContextState] = useContext(AnalysisContext);
  const systemOS = getSystemOs();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { sharingPreview } = useQueryParams();
  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);
  // In print mode, we don't have the routing to get the analysisId from the params, so we need to get it from the pathname
  const analysisIdPrintMode = parseInt(useLocation().pathname.split("/").at(2));
  const isSharing = !!analysisContextState.analysisDetails.sharing;
  const hasPageAccess =
    !isSharing ||
    analysisContextState.analysisDetails.sharing.summaryAccess === SharingSummaryAccess.Shared;

  const getCriticalInsight = () =>
    Object.keys(state.summaryOverview).filter((key) => WidgetType[key]);

  const handlePrintAction = () => {
    if (printMode) {
      window.print();
    } else {
      const newWindow = window.open(
        `/analysis/${analysisId}/print-summary`,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  useEffect(() => {
    dispatch(
      fetchSummaryOverview({
        analysisId: printMode ? analysisIdPrintMode : analysisId,
        user_id: analysisContextState.previewUser.id,
      })
    );

    setTimeout(() => {
      dispatch(setIsLoadingAnimationActive(false));
    }, 2500);

    return () => {
      dispatch(clearState());
    };
  }, [printMode, analysisIdPrintMode, analysisId, analysisContextState.previewUser, dispatch]);

  if (!features.llm) history.push(routes.homePage);

  if (!hasPageAccess) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <StyledSummaryContainer className="fade-in" printMode={printMode}>
      <StyledHeader>
        <StyledNamePrint>
          <StyledUserName>
            <TextTruncator
              value={
                <Text
                  resource={{
                    key: "summary.header.userName.title",
                    args: [
                      `${
                        sharingPreview
                          ? analysisContextState.previewUser.firstname
                          : currentUser.firstname
                      }`,
                    ],
                  }}
                />
              }
              customWidth="420px"
            />
          </StyledUserName>
          <StyledPrintButton>
            {!state.isLoading && (
              <Button
                variant={ButtonVariant.primary}
                size={ButtonSize.sm}
                onClick={() => handlePrintAction()}
                disabled={!!sharingPreview}
              >
                {printMode ? (
                  <>
                    <Icon
                      type={IconType.print}
                      size={16}
                      color={Color.blue50}
                      style={{ marginRight: "6px" }}
                    />
                    <Text resource={"button.print"} />
                  </>
                ) : (
                  <>
                    <Text resource={"button.printableVersion"} />
                    <Icon
                      type={IconType.externalLink}
                      size={16}
                      color={Color.blue50}
                      style={{ marginLeft: "6px" }}
                    />
                  </>
                )}
              </Button>
            )}
          </StyledPrintButton>
        </StyledNamePrint>
        <StyledHeaderCaption>
          <Text resource="summary.header.caption" />
        </StyledHeaderCaption>
      </StyledHeader>

      <StyledBodyContainer printMode={printMode}>
        {state.isLoading || (state.isLoadingAnimationActive && !state.isCachedResponse) ? (
          <StyledAnimationContainer>
            <video autoPlay loop muted playsInline>
              <source
                src={systemOS === SystemOS.Mac ? nlpLoadingMac : nlpLoading}
                type="video/webm"
              />
            </video>
            <StyledAnimationTextContainer>
              <StyledHeaderText>
                <Text resource="summary.animation.header" />
                <span />
              </StyledHeaderText>
              <StyledDescriptionText>
                <Text resource="summary.animation.description" />
              </StyledDescriptionText>
            </StyledAnimationTextContainer>
          </StyledAnimationContainer>
        ) : (
          <>
            <StyledSummary>
              <AnimatedText
                text={state.summaryOverview.sections.reduce(
                  (acc, section, index) =>
                    acc +
                    section.content +
                    (index !== state.summaryOverview.sections.length - 1 ? "\n\n" : ""),
                  ""
                )}
                speed={state.isCachedResponse ? 0 : 25}
              />
            </StyledSummary>
            {(state.isSummaryAnimationComplete || state.isCachedResponse) &&
              getCriticalInsight().length !== 0 && (
                <MostCriticalInsights fadeIn={!state.isCachedResponse} />
              )}
          </>
        )}
      </StyledBodyContainer>
    </StyledSummaryContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const StyledNamePrint = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-start;
  gap: 6px;
`;

const StyledPrintButton = styled.div`
  @media print {
    display: none;
  }
  align-self: flex-end;
  flex-shrink: 1;
`;

const StyledUserName = styled.div`
  flex-grow: 2;
  font-weight: bold;
  font-size: 32px;
  color: ${Color.indigo60};
`;

const StyledHeaderCaption = styled.div`
  color: ${Color.gray40};
  font-size: 32px;
`;

const StyledSummaryContainer = styled.div<{ printMode: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: ${({ printMode }) => printMode && "40px 25%"};
  @media print {
    padding: 40px;
  }
`;

const StyledBodyContainer = styled.div<{ printMode: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: ${({ printMode }) => !printMode && "40px 40px 32px"};
  background-color: ${Color.white};
  border-radius: 10px;
`;

const StyledAnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px;
  max-height: 500px;

  video {
    width: 210px;
    height: 100%;
    max-height: 100%;
    transition: opacity ${FRAME_TRANSITION};
  }
`;

const StyledAnimationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledHeaderText = styled.div`
  display: flex;
  margin-left: 15px;
  font-weight: bold;
  font-size: 24px;
  padding: 8px;

  span:before {
    animation: dots 2s linear infinite;
    content: "";

    @keyframes dots {
      0%,
      20% {
        content: ".";
      }
      40% {
        content: "..";
      }
      60% {
        content: "...";
      }
      90%,
      100% {
        content: "";
      }
    }
  }
`;

const StyledDescriptionText = styled.div`
  display: flex;
  text-align: center;
`;

const StyledSummary = styled.div`
  color: ${Color.gray50};
`;
